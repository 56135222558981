import React, { Component } from 'react'
import { graphql } from 'gatsby'
import WorkListingLayout from '../../layouts/work/WorkListingLayout'
import WorkListItem from '../../components/work-list-item/WorkListItem'
import SEO from '../../components/seo'
import './work-featured.scss'

class WorkFeatured extends Component {
    render() {
        const pageData = this.props.data.wordpressPage
        const works = this.props.data.allWordpressWpWorks.edges
        return (
            <WorkListingLayout theme="dark">
                <SEO
                    yoast={pageData.yoast}
                    title={pageData.title}
                    description={pageData.content}
                    dateModified={pageData.modified}
                    datePublished={pageData.date}
                />
                <section className="WorkFeatured container-fluid">
                    <ul className="list-unstyled row">
                        {works.map(({ node: work }, index) => {
                            const {
                                id,
                                slug,
                                title,
                                worktypes: categories,
                                featured_media,
                            } = work
                            let className = `list-group-item mb-yd col-condensed col-12 col-md-6`
                            let listItemVariant = 'default'
                            if ('the-design-team-wants-this' === true) {
                                className = `list-group-item mb-yd col-full col-12`
                                listItemVariant = 'featured'
                            }
                            return (
                                <li key={id} className={className}>
                                    <WorkListItem
                                        slug={slug}
                                        title={title}
                                        categories={categories}
                                        featuredImage={
                                            featured_media
                                                ? featured_media.localFile
                                                : null
                                        }
                                        variant={listItemVariant}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </section>
            </WorkListingLayout>
        )
    }
}

export default WorkFeatured

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
        }
        allWordpressWpWorks(
            sort: { order: ASC, fields: [menu_order] }
            filter: { workstatus: { elemMatch: { name: { eq: "Featured" } } } }
        ) {
            edges {
                node {
                    id
                    title
                    wordpress_id
                    content
                    slug
                    worktypes {
                        name
                    }
                    featured_media {
                        alt_text
                        title
                        id
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1800, maxHeight: 1200) {
                                    ...GatsbyImageSharpFluid
                                }
                                fluidWide: fluid(
                                    maxWidth: 1340
                                    maxHeight: 620
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
