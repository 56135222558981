import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/site-header'
import Footer from '../../components/site-footer'
import LayoutBase from '../LayoutBase'
import { Link } from 'gatsby'
import classNames from 'classnames'
import './WorkListingLayout.scss'

const WorkListingLayout = ({
    children,
    className,
    headerProps = {},
    ...props
}) => (
    <LayoutBase
        className={classNames('WorkListingLayout', className)}
        {...props}
    >
        <Header {...headerProps} />
        <main className="px-yd">
            <nav role="navigation" className="listing-type-nav">
                <ul className="list-unstyled d-flex">
                    <li style={{ marginRight: 50 }}>
                        <Link
                            className="type-link"
                            activeClassName="active"
                            to={`/projects`}
                        >{`Featured work`}</Link>
                    </li>
                    <li>
                        <Link
                            className="type-link"
                            activeClassName="active"
                            to={`/projects/archive`}
                        >{`Index`}</Link>
                    </li>
                </ul>
            </nav>
            {children}
        </main>
        <Footer />
    </LayoutBase>
)

WorkListingLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
WorkListingLayout.defaultProps = {
    theme: 'dark',
}

export default WorkListingLayout
