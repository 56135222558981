import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Hoverable from '../hoverable/Hoverable'
import './WorkListItem.scss'

const WorkListItem = ({ slug, title, categories, featuredImage, variant }) => {
    if (
        variant === 'featured' &&
        featuredImage &&
        !featuredImage.childImageSharp.fluidWide
    ) {
        console.warn(
            `WorkListItem with variant "featured" requires the following field: featuredImage.childImageSharp.fluidWide`
        )
    }
    const imageVariant = featuredImage
        ? variant === 'featured'
            ? featuredImage.childImageSharp.fluidWide
            : featuredImage.childImageSharp.fluid
        : false
    return (
        <Link className={`WorkListItem ${variant}`} to={`/projects/${slug}`}>
            <Hoverable.Container>
                <Hoverable.Overlay>
                    {featuredImage ? (
                        <Img
                            fadeIn={false}
                            fluid={imageVariant}
                            alt={featuredImage.alt_text}
                            title={featuredImage.title}
                            //sizes={{...imageVariant, aspectRatio:16/9}}
                        />
                    ) : (
                        <div className="featured-image-placeholder" />
                    )}
                </Hoverable.Overlay>
                <div className="title-container">
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                    <div
                        className={`type-label ${
                            variant === 'default' ? 'text-label' : 'text-white'
                        }`}
                    >
                        {categories.map(cateogry => cateogry.name).join(' / ')}
                    </div>
                </div>
            </Hoverable.Container>
        </Link>
    )
}

WorkListItem.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired })
    ).isRequired,
    featuredImage: PropTypes.object,
    variant: PropTypes.oneOf(['default', 'featured']).isRequired,
}

WorkListItem.defaultProps = {
    slug: '',
    title: '',
    categories: [],
    featuredImage: null,
    variant: 'default',
}

export default WorkListItem
